var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "550px", "max-height": "551px", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "px-0 pb-0" },
        [
          _c(
            "v-card-title",
            { staticClass: "pl-4" },
            [
              _c(
                "span",
                {
                  staticClass: "text-h5",
                  style:
                    "color: #182026;font-weight:500;font-size:20px !important",
                },
                [_vm._v(_vm._s(_vm.titulo))]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "ma-0 pa-4" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "pa-0 ma-0",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _vm.isEdit
                        ? _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                style: "max-width:520px !important;",
                                attrs: {
                                  color: "primary",
                                  disabled: "",
                                  label: "CNPJ",
                                  outlined: "",
                                  "persistent-placeholder": "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.crfToEdit.cnpj,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.crfToEdit, "cnpj", $$v)
                                  },
                                  expression: "crfToEdit.cnpj",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isEdit
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-autocomplete", {
                                style:
                                  "max-width:525px !important;min-height:48px!important;max-height:48px!important",
                                attrs: {
                                  id: "selectEmpresa",
                                  label: "Empresa *",
                                  items: _vm.userEstablishment,
                                  "item-text": "name",
                                  "item-value": (item) =>
                                    `${item.cnpj + ";" + item.name}`,
                                  multiple: false,
                                  "append-icon": "mdi-chevron-down",
                                  dense: "",
                                  "hide-details": "",
                                  outlined: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function ({ item, attrs, on }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  class: {
                                                    "pl-10": item.group,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          active,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-list-item-action",
                                                              {
                                                                attrs: {
                                                                  id:
                                                                    item.cnpj +
                                                                    "check",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      "input-value":
                                                                        active,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                attrs: {
                                                                  id: item.cnpj,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "cnpj"
                                                                        )(
                                                                          item.cnpj
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-list-item-subtitle",
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  759501883
                                ),
                                model: {
                                  value: _vm.crfToEdit.cnpj,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.crfToEdit, "cnpj", $$v)
                                  },
                                  expression: "crfToEdit.cnpj",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-list-item",
                            { staticClass: "pr-4 ml-n1" },
                            [
                              _c("v-combobox", {
                                staticClass: "'text-wrap'",
                                style:
                                  "max-width:241px !important;min-height:48px!important;max-height:48px!important;",
                                attrs: {
                                  id: "comboSituation",
                                  color: "var(--v-primary-base)",
                                  items: _vm.situacoes,
                                  label: "Situação *",
                                  outlined: "",
                                  dense: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.habilitarCampos()
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function ({ item, attrs, on }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: { id: `item${item}` },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        active,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              attrs: {
                                                                id: `item${item}content`,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  attrs: {
                                                                    id: `item${item}title`,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(item)
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "selection",
                                    fn: function (item) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "primary--text",
                                            attrs: { id: "itemSelected" },
                                          },
                                          [_vm._v(" " + _vm._s(item.item))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.crfToEdit.situacao,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.crfToEdit, "situacao", $$v)
                                  },
                                  expression: "crfToEdit.situacao",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0 pr-3", attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            staticClass: "primary--text",
                            style: _vm.isHability
                              ? "background-color:#E7E7FA;max-width:261px !important;min-height:40px!important;max-height:40px!important"
                              : "min-height:40px!important;max-height:40px!important;",
                            attrs: {
                              id: "idCodigo",
                              rules: _vm.codigo,
                              maxlength: "22",
                              minlength: "22",
                              hint: "Preencha 22 caracteres (A-z, 0-9)",
                              "persistent-hint": _vm.persistentCodigo,
                              label: "Código *",
                              dense: "",
                              outlined: "",
                              disabled: _vm.isHability,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "message",
                                fn: function ({ message }) {
                                  return [
                                    _c("span", {
                                      domProps: { innerHTML: _vm._s(message) },
                                    }),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.crfToEdit.codigo,
                              callback: function ($$v) {
                                _vm.$set(_vm.crfToEdit, "codigo", $$v)
                              },
                              expression: "crfToEdit.codigo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-list-item",
                            { staticClass: "pa-4 ml-n1" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    disabled: _vm.isHability,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "##/##/####",
                                                    expression: "'##/##/####'",
                                                  },
                                                ],
                                                style: _vm.isHability
                                                  ? "background-color: #E7E7FA ;min-height:40px!important;max-height:40px!important;"
                                                  : "min-height:40px!important;max-height:40px!important;",
                                                attrs: {
                                                  id: "textPicker",
                                                  disabled: _vm.isHability,
                                                  rules: _vm.emissao,
                                                  outlined: "",
                                                  label: "Emissão *",
                                                  "append-icon": "mdi-calendar",
                                                  hint: "Preencha a data atual ou retroativa",
                                                  readonly: "",
                                                  dense: "",
                                                  "persistent-hint":
                                                    _vm.persistentHintEmissao,
                                                  color: _vm.filterDateColor,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "message",
                                                      fn: function ({
                                                        message,
                                                      }) {
                                                        return [
                                                          _vm.persistentHintEmissao
                                                            ? _c("span", {
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      message
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.dateEmissaoFormated,
                                                  callback: function ($$v) {
                                                    _vm.dateEmissaoFormated =
                                                      $$v
                                                  },
                                                  expression:
                                                    "dateEmissaoFormated",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.modalPickerEmissao,
                                    callback: function ($$v) {
                                      _vm.modalPickerEmissao = $$v
                                    },
                                    expression: "modalPickerEmissao",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "", min: _vm.minDate },
                                    on: {
                                      input: function ($event) {
                                        _vm.modalPickerEmissao = false
                                      },
                                    },
                                    model: {
                                      value: _vm.crfToEdit.emissao,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.crfToEdit, "emissao", $$v)
                                      },
                                      expression: "crfToEdit.emissao",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0 pr-4", attrs: { sm: "6" } },
                        [
                          _c(
                            "v-list-item",
                            { staticClass: "pa-0 pt-4 mr-n1" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    disabled: _vm.isHability,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "##/##/####",
                                                    expression: "'##/##/####'",
                                                  },
                                                ],
                                                style: _vm.isHability
                                                  ? "background-color: #E7E7FA ;min-height:40px!important;max-height:40px!important;"
                                                  : "min-height:40px!important;max-height:40px!important;",
                                                attrs: {
                                                  id: "textPicker2",
                                                  disabled: _vm.isHability,
                                                  outlined: "",
                                                  rules: _vm.validade,
                                                  label: "Validade *",
                                                  hint: "Preencha uma data futura",
                                                  "persistent-hint":
                                                    _vm.persistentHintValidade,
                                                  "append-icon": "mdi-calendar",
                                                  readonly: "",
                                                  dense: "",
                                                  color: _vm.filterDateColor,
                                                },
                                                model: {
                                                  value:
                                                    _vm.dateValidadeFormated,
                                                  callback: function ($$v) {
                                                    _vm.dateValidadeFormated =
                                                      $$v
                                                  },
                                                  expression:
                                                    "dateValidadeFormated",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.modalPickerValidade,
                                    callback: function ($$v) {
                                      _vm.modalPickerValidade = $$v
                                    },
                                    expression: "modalPickerValidade",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "", min: _vm.minDate },
                                    on: {
                                      input: function ($event) {
                                        _vm.modalPickerValidade = false
                                      },
                                    },
                                    model: {
                                      value: _vm.crfToEdit.validade,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.crfToEdit, "validade", $$v)
                                      },
                                      expression: "crfToEdit.validade",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-n4" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-4 pb-0", attrs: { sm: "12" } },
                        [
                          _c("span", [
                            _vm._v(
                              "Tamanho máximo do arquivo é 10MB. O tipo de arquivo suportado é .pdf"
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3",
                          style: _vm.isHability
                            ? "border:1px solid #E7E7FA; color:#E7E7FA;"
                            : "border:1px solid primary;",
                          attrs: {
                            id: "btnFilePDF",
                            sm: "6",
                            disabled: _vm.isHability,
                          },
                          on: { click: _vm.uploadPDF },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "material-symbols-outlined" },
                            [_vm._v(" drive_folder_upload ")]
                          ),
                          _vm._v("Adicionar arquivo * "),
                        ]
                      ),
                      _c("input", {
                        ref: "pdfInput",
                        staticStyle: { display: "none" },
                        attrs: { type: "file", accept: "application/pdf" },
                        on: { change: _vm.handleFileUpload },
                      }),
                      _vm.pdfFile
                        ? _c(
                            "div",
                            {
                              staticClass: "btnFilePDFName",
                              attrs: { sm: "6" },
                            },
                            [
                              _c(
                                "span",
                                [
                                  _vm._v(_vm._s(_vm.pdfFile.name) + " "),
                                  _c(
                                    "v-icon",
                                    { on: { click: _vm.removePDF } },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "btnFilePDFName",
                              attrs: { sm: "6" },
                            },
                            [_c("span", [_vm._v("Nenhum arquivo selecionado")])]
                          ),
                      _c("v-col", { attrs: { sm: "12" } }, [
                        _vm.fileSizeError
                          ? _c("p", { staticClass: "btnFileError" }, [
                              _vm._v(
                                "O arquivo não pode superar o tamanho de 10MB!"
                              ),
                            ])
                          : _vm._e(),
                        _vm.fileTypeError
                          ? _c("p", { staticClass: "btnFileError" }, [
                              _vm._v("Adicione um arquivo .pdf."),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "text-left pt-8 pl-2",
                  staticStyle: {
                    "font-size": "14px",
                    color: "#575767",
                    "font-weight": "400",
                  },
                },
                [_vm._v(" * Campos de preenchimento obrigatório ")]
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            {
              staticStyle: {
                "padding-bottom": "0px !important",
                "margin-bottom": "16px !important",
              },
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { id: "btnCancel", text: "" },
                  on: { click: _vm.close },
                },
                [_vm._v(" Cancelar")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "btnConfirmOredit",
                    color: "success",
                    loading: _vm.loadingSave,
                    disabled: _vm.habilitarBotaoSalvarEditar,
                  },
                  on: { click: _vm.acao },
                },
                [_vm._v(" " + _vm._s(_vm.labelBotao) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "370" },
          model: {
            value: _vm.acaoSucess,
            callback: function ($$v) {
              _vm.acaoSucess = $$v
            },
            expression: "acaoSucess",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5" },
                [
                  _c(
                    "v-alert",
                    { attrs: { dense: "", text: "", type: "success" } },
                    [
                      _vm._v(
                        "Certidão " +
                          _vm._s(_vm.tipoAcao.toLowerCase()) +
                          " com sucesso."
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", ib: "btnConfirm" },
                      on: { click: _vm.cadastroSucess },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }