<template>
  <v-dialog v-model="show" max-width="550px" max-height="551px" persistent>
    <v-card class="px-0 pb-0">
      <v-card-title class="pl-4">
        <span class="text-h5" :style="'color: #182026;font-weight:500;font-size:20px !important'">{{ titulo }}</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-4">
        <v-form ref="form" v-model="valid" lazy-validation class="pa-0 ma-0">
          <v-row>
            <v-col v-if="isEdit" cols="12" class="pb-0">
              <v-text-field
                v-model="crfToEdit.cnpj"
                color="primary"
                disabled
                label="CNPJ"
                outlined
                persistent-placeholder
                dense
                :style="'max-width:520px !important;'"
              />
            </v-col>
            <v-col v-if="!isEdit" cols="12">
              <v-autocomplete
                id="selectEmpresa"
                v-model="crfToEdit.cnpj"
                label="Empresa *"
                :items="userEstablishment"
                item-text="name"
                :item-value="(item) => `${item.cnpj + ';' + item.name}`"
                :multiple="false"
                append-icon="mdi-chevron-down"
                dense
                hide-details
                outlined
                :style="'max-width:525px !important;min-height:48px!important;max-height:48px!important'"
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item #default="{ active }" v-bind="attrs" :class="{ 'pl-10': item.group }" v-on="on">
                    <v-list-item-action :id="item.cnpj + 'check'">
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content :id="item.cnpj">
                      <v-list-item-title>{{ item.cnpj | cnpj }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-0">
              <v-list-item class="pr-4 ml-n1">
                <v-combobox
                  id="comboSituation"
                  v-model="crfToEdit.situacao"
                  color="var(--v-primary-base)"
                  :items="situacoes"
                  label="Situação *"
                  outlined
                  dense
                  class="'text-wrap'"
                  :style="'max-width:241px !important;min-height:48px!important;max-height:48px!important;'"
                  @change="habilitarCampos()"
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" :id="`item${item}`" #default="{ active }" v-on="on">
                      <v-list-item-content :id="`item${item}content`">
                        <v-list-item-title :id="`item${item}title`">{{ item }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template slot="selection" slot-scope="item">
                    <span id="itemSelected" class="primary--text"> {{ item.item }}</span>
                  </template>
                </v-combobox>
              </v-list-item>
            </v-col>

            <v-col cols="6" class="pa-0 pr-3">
              <v-text-field
                id="idCodigo"
                v-model="crfToEdit.codigo"
                class="primary--text"
                :rules="codigo"
                maxlength="22"
                minlength="22"
                hint="Preencha 22 caracteres (A-z, 0-9)"
                :persistent-hint="persistentCodigo"
                label="Código *"
                dense
                outlined
                :disabled="isHability"
                :style="
                  isHability
                    ? 'background-color:#E7E7FA;max-width:261px !important;min-height:40px!important;max-height:40px!important'
                    : 'min-height:40px!important;max-height:40px!important;'
                "
              >
                <template v-slot:message="{ message }"> <span v-html="message"></span> </template></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-0">
              <v-list-item class="pa-4 ml-n1">
                <v-menu v-model="modalPickerEmissao" :close-on-content-click="false" :disabled="isHability">
                  <v-date-picker
                    v-model="crfToEdit.emissao"
                    no-title
                    :min="minDate"
                    @input="modalPickerEmissao = false"
                  ></v-date-picker>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      id="textPicker"
                      v-model="dateEmissaoFormated"
                      v-mask="'##/##/####'"
                      :disabled="isHability"
                      :rules="emissao"
                      outlined
                      label="Emissão *"
                      append-icon="mdi-calendar"
                      hint="Preencha a data atual ou retroativa"
                      readonly
                      dense
                      :persistent-hint="persistentHintEmissao"
                      :color="filterDateColor"
                      :style="
                        isHability
                          ? 'background-color: #E7E7FA ;min-height:40px!important;max-height:40px!important;'
                          : 'min-height:40px!important;max-height:40px!important;'
                      "
                      v-on="on"
                    >
                      <template v-slot:message="{ message }">
                        <span v-if="persistentHintEmissao" v-html="message"></span>
                      </template>
                    </v-text-field>
                  </template>
                </v-menu>
              </v-list-item>
            </v-col>

            <v-col sm="6" class="pa-0 pr-4">
              <v-list-item class="pa-0 pt-4 mr-n1">
                <v-menu v-model="modalPickerValidade" :close-on-content-click="false" :disabled="isHability">
                  <v-date-picker
                    v-model="crfToEdit.validade"
                    no-title
                    :min="minDate"
                    @input="modalPickerValidade = false"
                  ></v-date-picker>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      id="textPicker2"
                      v-model="dateValidadeFormated"
                      v-mask="'##/##/####'"
                      :disabled="isHability"
                      outlined
                      :rules="validade"
                      label="Validade *"
                      hint="Preencha uma data futura"
                      :persistent-hint="persistentHintValidade"
                      append-icon="mdi-calendar"
                      readonly
                      dense
                      :style="
                        isHability
                          ? 'background-color: #E7E7FA ;min-height:40px!important;max-height:40px!important;'
                          : 'min-height:40px!important;max-height:40px!important;'
                      "
                      :color="filterDateColor"
                      v-on="on"
                    ></v-text-field>
                  </template>
                </v-menu>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row class="mb-n4">
            <v-col sm="12" class="px-4 pb-0">
              <span>Tamanho máximo do arquivo é 10MB. O tipo de arquivo suportado é .pdf</span>
            </v-col>
            <v-btn
              id="btnFilePDF"
              class="ml-3"
              sm="6"
              :disabled="isHability"
              :style="isHability ? 'border:1px solid #E7E7FA; color:#E7E7FA;' : 'border:1px solid primary;'"
              @click="uploadPDF"
            >
              <span class="material-symbols-outlined"> drive_folder_upload </span>Adicionar arquivo *
            </v-btn>
            <input
              ref="pdfInput"
              type="file"
              accept="application/pdf"
              style="display: none"
              @change="handleFileUpload"
            />

            <div v-if="pdfFile" sm="6" class="btnFilePDFName">
              <span>{{ pdfFile.name }} <v-icon @click="removePDF">mdi-close</v-icon></span>
            </div>
            <div v-else sm="6" class="btnFilePDFName">
              <span>Nenhum arquivo selecionado</span>
            </div>
            <v-col sm="12">
              <p v-if="fileSizeError" class="btnFileError">O arquivo não pode superar o tamanho de 10MB!</p>
              <p v-if="fileTypeError" class="btnFileError">Adicione um arquivo .pdf.</p>
            </v-col>
          </v-row>
        </v-form>
        <div class="text-left pt-8 pl-2" style="font-size: 14px; color: #575767; font-weight: 400">
          * Campos de preenchimento obrigatório
        </div>
      </v-card-text>

      <v-card-actions style="padding-bottom: 0px !important; margin-bottom: 16px !important">
        <v-spacer />
        <v-btn id="btnCancel" text @click="close"> Cancelar</v-btn>
        <v-btn
          id="btnConfirmOredit"
          color="success"
          :loading="loadingSave"
          :disabled="habilitarBotaoSalvarEditar"
          @click="acao"
        >
          {{ labelBotao }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="acaoSucess" persistent max-width="370">
      <v-card>
        <v-card-title class="text-h5">
          <v-alert dense text type="success">Certidão {{ tipoAcao.toLowerCase() }} com sucesso.</v-alert>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" ib="btnConfirm" @click="cadastroSucess"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import crfService from '@/services/crfService.js';
import AdminServices from '@/services/adminServices.js';
import sortBy from 'lodash/sortBy';
import { mapGetters } from 'vuex';
import { eventBus } from '../../../../main.js';
import gedService from '@/services/gedService.js';

export default {
  name: 'ModalCrfNova',
  props: ['crf'],

  data() {
    return {
      nomeArquivo: null,
      pdfFile: null,
      fileSizeError: false,
      fileTypeError: false,
      historicUpdate: {
        nome: '',
        email: '',
        cnpj: '',
      },

      arquivo: '',
      arquivoBase64: '',
      persistentHintValidade: true,
      persistentHintEmissao: true,
      persistentCodigo: true,
      codigo: [
        (value) => {
          if (value) {
            if (value.length === 22) {
              this.persistentCodigo = false;
              return true;
            } else {
              return 'Preencha 22 caracteres (A-z, 0-9)';
            }
          } else {
            return true;
          }
        },
      ],

      emissao: [
        (value) => {
          if (value) {
            return this.testDateEmissao(value);
          } else {
            return true;
          }
        },
      ],

      validade: [
        (value) => {
          if (value) {
            return this.testDateValidade(value);
          } else {
            return true;
          }
        },
      ],

      modalPickerEmissao: false,
      modalPickerValidade: false,
      valid: true,
      isHability: false,
      disabled: 'disabled',
      validOrNot: '',
      userEstablishment: [],
      show: false,
      loadingSave: false,
      obrigatorio: 'Obrigatório',
      cnd_file: null,
      pendecy_file: null,
      titulo: '',
      labelBotao: '',
      acaoSucess: false,
      validacaoError: false,
      tipoAcao: '',
      mensagemErro: '',
      isEdit: false,
      //SOLICITADO NA ESTÓRIA AG-272
      situacoes: ['Irregular', 'Regular'],
      inclusoes: ['Automático', 'Manual'],
      mensagemFile: '',
      isCndOrCpen: false,
      historicos: [],
      filterDateColor: 'primary',
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
      userInfo: 'user/userInfo',
    }),

    minDate() {
      const currentDate = new Date();
      return currentDate.toISOString();
    },

    crfToEdit() {
      // if (this.crf) {
      //   if (this.crf.idArquivoGED){

      //     let response = '';
      //     gedService.files.get(this.crf.idArquivoGED).then(data =>{
      //       this.nomeArquivo = data.name
      //       response =  data;
      //     });

      //   }
      // }

      return this.crf;
    },

    dateEmissaoFormated: {
      get() {
        if (this.crfToEdit.emissao) {
          var dataFormatada = this.formatDate(this.crfToEdit.emissao);
          return dataFormatada;
        } else {
          return null;
        }
      },

      set(data) {
        return data;
      },
    },

    dateValidadeFormated: {
      get() {
        if (this.crfToEdit.validade) {
          var dataFormatada = this.formatDate(this.crfToEdit.validade);
          return dataFormatada;
        } else {
          return null;
        }
      },

      set(data) {
        return data;
      },
    },

    habilitarBotaoSalvarEditar: function () {
      if (
        this.crfToEdit.cnpj &&
        this.crfToEdit.codigo &&
        this.crfToEdit.situacao == 'Regular' &&
        this.crfToEdit.emissao &&
        this.crfToEdit.validade &&
        this.crfToEdit.pdf
      ) {
        this.valid = false;
      } else if (this.crfToEdit.situacao == 'Irregular' && this.crfToEdit.cnpj) {
        this.valid = false;
        return;
      }

      var messageEmissao = this.testDateEmissao(this.crfToEdit.emissao);

      if (this.crfToEdit.codigo) {
        if (this.crfToEdit.codigo.length < 23) {
          this.valid = true;
        } else {
          if (this.persistentHintEmissao === true || this.persistentHintValidade === true) {
            this.valid = true;
          } else {
            this.valid = false;
          }
        }
      } else {
        this.valid = true;
      }
      if (this.pdfFile != null || this.pdfFile != undefined) {
        if (this.crfToEdit.codigo.length == 22) {
          this.valid = false;
        } else {
          this.valid = true;
        }
      }

      return this.valid;
    },
  },

  created() {
    this.init();
    this.getCompanies();
  },

  methods: {
    testDateEmissao(date) {
      if (!date) {
        return;
      }
      const parts = date.split('/').map((p) => parseInt(p, 10));
      parts[1] -= 1;
      const d = new Date(parts[2], parts[1], parts[0]);

      let atual = new Date();

      if (d.getTime() <= atual.getTime()) {
        this.persistentHintEmissao = false;
        return true;
      } else {
        this.persistentHintEmissao = true;
        this.valid = true;
        return 'Preencha a data atual ou retroativa';
      }
    },

    testDateValidade(date) {
      const parts = date.split('/').map((p) => parseInt(p, 10));
      parts[1] -= 1;
      const d = new Date(parts[2], parts[1], parts[0]);

      let atual = new Date();

      if (d.getTime() > atual.getTime()) {
        this.persistentHintValidade = false;
        return true;
      } else {
        this.persistentHintValidade = true;
        return 'Preencha uma data futura';
      }
    },

    habilitarCampos() {
      if (this.crfToEdit.situacao == 'Irregular') {
        this.isHability = true;
        this.valid = false;
      } else {
        this.isHability = false;
        this.valid = true;
      }
    },

    init() {
      this.$on('open', this.open);
      this.$on('edit', this.edit);
      this.$on('close', this.close);
    },

    async open() {
      this.titulo = 'Adicionar certidão';
      this.labelBotao = 'Salvar';
      this.isEdit = false;
      this.acaoSucess = false;
      this.validacaoError = false;
      this.show = true;
    },

    async edit() {
      this.titulo = 'Editar certidão';
      this.labelBotao = 'Alterar';
      this.isEdit = true;
      this.acaoSucess = false;
      this.validacaoError = false;
      this.show = true;
    },

    close() {
      this.isCndOrCpen = false;
      this.mensagemErro = false;
      this.show = false;
      this.pdfFile = null;
      this.$emit('refresh');
    },

    async getCompanies() {
      let { data } = await AdminServices.userEstablishment.get(this.usuario.id);
      let userEstablishments = data.map((item) => {
        return { name: item.establishment.socialName, cnpj: item.establishment.details.cnpj };
      });
      userEstablishments = sortBy(userEstablishments, ['name']);
      let establishments = userEstablishments.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
      this.userEstablishment = establishments;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.toString().split('-');
      return `${day}/${month}/${year}`;
    },

    isBase64(str) {
      try {
        return btoa(atob(str)) === str ? true : false;
      } catch (e) {
        return false;
      }
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async acao() {
      if (this.crfToEdit.pdf) {
        let isBase64Valid = this.isBase64(this.crfToEdit.pdf);

        if (!isBase64Valid) {
          await this.getBase64(this.crfToEdit.pdf).then((data) => {
            this.crfToEdit.pdf = data;
          });
        }
      }

      if (!this.validacaoError) {
        this.crfToEdit.historicos = this.historicos;
        if (this.isEdit) {
          this.alterar(this.crfToEdit);
        } else {
          this.salvar(this.crfToEdit);
        }
        this.saveHistoricUpdate();
      }
    },

    async uploadArquivo(arquivo) {
      let fileToUpload = {
        name: 'certificado_fgts.pdf',
        user: this.userInfo.username,
        estab: this.crf.cnpj,
        company: this.crf.cnpj.substring(0, 8),
        userid: this.userInfo.id,
        clienteid: 'web-front-end',
        visibility: false,
        base64: arquivo.split(',')[1],
      };

      let responseGed = await gedService.files.post(fileToUpload);
      return responseGed.data;
    },

    async salvar(crf) {
      this.tipoAcao = 'Salva';
      let response = '';

      if (this.isValidCrf(crf)) {
        try {
          if (crf.pdf) {
            crf.idArquivoGED = await this.uploadArquivo(crf.pdf);
          }
          response = await crfService.crf.save(crf);
          this.loadingSave = false;
          this.showToast(`Certidão adicionada com sucesso!`, 'success', 78);
          this.close();
        } catch (e) {
          console.log('', e);
          this.loadingSave = false;
          this.mensagemErro = e.response.data.message.split(':')[1];
          this.validacaoError = true;
        }
      }
    },

    isValidCrf(crf) {
      if (crf && crf.emissao && crf.validade) {
        let emissao = new Date(crf.emissao);
        let validade = new Date(crf.validade);
        let now = new Date();
        return emissao <= now && validade >= now;
      } else if (crf.situacao === 'Irregular' && crf.cnpj) {
        return true;
      }
      return false;
    },

    cadastroSucess() {
      this.acaoSucess = false;
      this.close();
    },

    async alterar(crf) {
      this.tipoAcao = 'alterada';

      let response = '';

      if (crf.situacao === 'Irregular') {
        crf.codigo = '';
        crf.emissao = '';
        crf.pdf = '';
        crf.validade = '';
      }

      if (crf.pdf) {
        try {
          crf.idArquivoGED = await this.uploadArquivo(this.arquivo, crf.pdf);
        } catch (e) {
          console.log('e', e);
          return false;
        }
      }
      response = await crfService.crf.update(crf.id, crf);

      if (response.data.message) {
        this.loadingSave = false;
        this.showToast(`Certidão adicionada com sucesso!`, 'success', 78);
        this.close();
      } else {
        this.acaoError = true;
      }
      this.loadingSave = false;
      this.showToast(`Alterações realizadas com sucesso!`, 'success', 78);
    },

    obterArquivo(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },

    saveHistoricUpdate() {
      this.historicUpdate.nome = this.userInfo.firstName;
      this.historicUpdate.email = this.userInfo.emailAddress;
      crfService.historic.save(this.historicUpdate);
    },

    uploadPDF() {
      this.$refs.pdfInput.click();
    },

    handleFileUpload(event) {
      if (event.target.files[0].type === 'application/pdf' && event.target.files[0].size <= 10000000) {
        this.pdfFile = event.target.files[0];
        this.fileSizeError = false;
        this.fileTypeError = false;
        this.crfToEdit.pdf = this.pdfFile;
      } else if (event.target.files[0].type !== 'application/pdf') {
        this.fileTypeError = true;
      } else {
        this.fileSizeError = true;
      }
    },

    removePDF() {
      this.pdfFile = null;
      this.crfToEdit.pdf = null;
    },
  },
};
</script>
<style scoped>
::v-deep .v-input__icon--append .v-icon {
  color: var(--v-primary-base);
}

::v-deep .v-input__icon--prepend-inner > i:nth-child(1) {
  color: var(--v-primary-base);
}
::v-deep .v-label .v-label--active .theme--light {
  color: var(--v-primary-base);
}
::v-deep .v-input--is-disabled label {
  color: #575767 !important;
}
::v-deep .v-label {
  color: var(--v-primary-base);
}

::v-deep .v-label--active {
  color: #575767 !important;
}

::v-deep .v-label--active:disabled {
  color: #575767 !important;
}

::v-deep #textPicker:disabled {
  color: #575767 !important;
}
::v-deep #textPicker {
  color: var(--v-primary-base);
}
::v-deep #textPicker2:disabled {
  color: #575767 !important;
}
::v-deep #textPicker2 {
  color: var(--v-primary-base);
}

::v-deep .v-select.v-text-field input {
  color: var(--v-primary-base);
}

::v-deep .v-messages__message {
  /* display: none; */
  background: none;
  text-align: end;
}
::v-deep .v-messages.error--text {
  background: url('https://api.iconify.design/bxs/error-circle.svg?color=rgba(150, 45, 51, 1)') no-repeat top left;
  text-align: left !important;
  flex: none;
}
#btnFilePDF {
  width: 209px;
  height: 48px;
  background: #ffffff;
  color: var(--v-primary-base);
  border: 1px solid var(--v-primary-base);
  border-radius: 4px;

  justify-content: center;
  align-items: center;
  padding: 12px 16px;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

.btnFilePDFName {
  height: 48px;

  align-items: center;
  padding: 16px;

  background: #f7f7fd;
  border: 0px solid #8686a2;
  border-radius: 0px 4px 4px 0px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--v-primary-base);
}

.btnFileError {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #610006;
  height: 16px;
  left: 0px;
}
</style>
